import { IconButton } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import Table from "../table";
import { DataGrid } from "@mui/x-data-grid"
const VendorTable = ({ rows, handleAddFriend, handleDetailOpen, handleDelete }) => {
    const columns = [
        { field: 'index', headerName: 'ลำดับ', width: 100, align: 'center'},
        { field: 'vendor_name', headerName: 'ชื่อโรงงาน/บริษัท', width: 200, align: 'center' },
        { field: 'ownner_name', headerName: 'ชื่อเจ้าของ', width: 300, align: 'center' },
        { field: 'product', headerName: 'สินค้า', width: 200, align: 'center' },
        { field: 'tel_no', headerName: 'เบอร์โทร', width: 200, align: 'center' },
        { field: 'status', headerName: 'สถานะ', width: 200,align: 'center' },
        {
            field: "add",
            headerName: "",
            width: 100,
            renderCell: (params) => (
                params.row.status === 'เพิ่มแล้ว' ? null : (
                    <IconButton color="primary" onClick={() => handleAddFriend(params.row)}>
                      <PersonAddIcon sx={{ color: '#15322D' }} />
                    </IconButton>
                  )
            ),
        },
        {
            field: "delete",
            headerName: "",
            flex: 1.5,
            align: 'center',
            renderCell: (params) => (
                params.row.status !== 'เพิ่มแล้ว' ? null : (
                <IconButton color="primary" onClick={() => handleDelete(params.row)}>
                    <DeleteOutlineIcon sx={{color:'#15322D'}}/>
                </IconButton>
                )
            ),
        },
        {
            field: "detail",
            headerName: "",
            flex: 1.5,
            align: 'center',
            renderCell: (params) => (
                <IconButton color="primary" onClick={() => handleDetailOpen(params.row.id)}>
                    <SearchIcon sx={{color:'#15322D'}}/>
                </IconButton>
            ),
        },
    ]

    return (
        <div style={{ height: 600, width: "100%" }}>
            <Table
                columns={columns}
                rows={rows}
            />
        </div>
    )
}

export default VendorTable