import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search'
import { DataGrid } from '@mui/x-data-grid';
import {
    Tooltip,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import Table from '../table';

const StockTable = ({ rows, orders, setRows, total, setTotal, selectedIds, setSelectedIds }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    // const [quantity, ]
    const [old_quantity, setOldQuantity] = useState(0);

    const handleAddClick = (row) => {
        const updatedRow = { ...row, order_quantity: row.order_quantity === 0 ? row.quantity : row.order_quantity };
        setSelectedRow(updatedRow);
        setOldQuantity(row.order_quantity ?? 0);
        setOpenDialog(true);
    };


    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    const handleQuantitySubmit = () => {
        const oldQuantity = parseFloat(old_quantity) || 0;  // ป้องกัน NaN
        const submittedQuantity = selectedRow.order_quantity !== '' ? parseFloat(selectedRow.order_quantity) : 0;
    
        // อัปเดตแถว
        const updatedRowsCopy = rows.map((row) =>
            row.id === selectedRow.id ? { ...row, order_quantity: submittedQuantity } : row
        );
        setRows(updatedRowsCopy)
 
        // console.log("setTotal",total)
        let tt = total - oldQuantity;  
        let sum = (tt + submittedQuantity).toFixed(2);  
        // console.log("setTotal",parseFloat(sum))

        setTotal(parseFloat(sum));
        var updatedSelectedIds = selectedIds.filter((item) => item.id !== selectedRow.id);
        if (submittedQuantity !== 0 && Number(sum) <= Number(orders.total_weight)) {
            const index = updatedSelectedIds.findIndex(item => item.id === selectedRow.id);
            if (index !== -1) {
                updatedSelectedIds[index] = { id: selectedRow.id, value: submittedQuantity.toFixed(2) };
            } else {
                updatedSelectedIds.push({ id: selectedRow.id, value: submittedQuantity.toFixed(2) });
            }
        }
    
        setSelectedIds(updatedSelectedIds);
        setOpenDialog(false);
    };
    
    
        // const handleQuantitySubmit = () => {
        //     const submittedQuantity = selectedRow.order_quantity !== '' ? selectedRow.order_quantity : 0;

        //     const updatedRowsCopy = rows.map((row) =>
        //         row.id === selectedRow.id ? { ...row, order_quantity: submittedQuantity } : row
        //     );
        //     setRows(updatedRowsCopy);
        //     let tt = total - parseFloat(old_quantity);
        //     let sum = parseFloat(tt + submittedQuantity).toFixed(2)
        //     console.log(sum)
        //     setTotal(sum);
        //     var updatedSelectedIds = selectedIds.filter((id) => id !== selectedRow.id);
        //     if (submittedQuantity !== 0 &&Number(sum)<=Number(orders.total_weight)) {
        //         const index = updatedSelectedIds.findIndex(item => item.id === selectedRow.id);
        //         if (index !== -1) {
        //         updatedSelectedIds[index].value =  parseInt(submittedQuantity).toFixed(2);
        //         } else {
        //         updatedSelectedIds.push({ id: selectedRow.id, value: parseInt(submittedQuantity).toFixed(2) });
        //         }
        //     }
        //     setSelectedIds(updatedSelectedIds);
        //     setOpenDialog(false);
        // };

    const columns = [
        { field: 'batch_no', headerName: 'หมายเลขชุด', flex: 0.2, align: 'center' },
        { field: 'product', headerName: 'สินค้า', flex: 0.2, align: 'center' },
        { field: 'create_date', headerName: 'วันที่ผลิต', flex: 0.2, align: 'center' },
        {
            field: 'quantity',
            headerName: 'จำนวนคงเหลือ',
            flex: 0.2,
            align: 'center',
            renderCell: (params) => (
                <div style={{ textAlign: 'center' }}>
                    {params.value} kg
                </div>
            )
        },
        {
            field: 'order_quantity',
            flex: 0.2,
            headerName: 'จำนวนที่ส่ง',
            align: 'center',
            renderCell: (params) => (
                <div style={{ textAlign: 'center' }}>
                    {params.value} kg
                </div>
            )
        },
        {
            field: 'accept',
            headerName: '',
            flex: 0.05,
            align: 'center',
            renderCell: (params) => (
                <Tooltip title={<Typography>เพิ่มสินค้า</Typography>}>
                    <IconButton color="primary" onClick={() => handleAddClick(params.row)}>
                        <AddIcon sx={{ color: '#15322D', fontSize: 35 }} />
                    </IconButton>
                </Tooltip>
            )
        },

    ];

    return (
        <div style={{ height: 600, width: '100%' }}>
            <Table
                rows={rows}
                columns={columns}
            />
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>กรอกจำนวนสินค้า</DialogTitle>
                <DialogContent>
                    <TextField
                        type="number"
                        value={selectedRow?.order_quantity ?? 0}
                        onChange={(e) => {
                            setSelectedRow({ ...selectedRow, order_quantity: e.target.value });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleQuantitySubmit} variant='contained' sx={{ backgroundColor: '#D5E9AC', color: '#15322D', px: 3 }}>
                        ตกลง
                    </Button>
                    <Button onClick={handleDialogClose} variant='contained' sx={{ backgroundColor: '#E7202C' }}>ย้อนกลับ</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StockTable;
