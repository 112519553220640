import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import moment from 'moment';

const OrderMaterialAgent = ({ detail }) => {
    const [expandedRows, setExpandedRows] = useState([]);

    const toggleRow = (rowId) => {
        const isRowExpanded = expandedRows.includes(rowId);
        setExpandedRows(
            isRowExpanded
                ? expandedRows.filter(id => id !== rowId)
                : [...expandedRows, rowId]
        );
    };


    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow sx={{backgroundColor : '#15322D'}}>
                        <TableCell  sx={{color : 'white'}}>เลขคำสั่งซื้อ</TableCell>
                        <TableCell  sx={{color : 'white'}}>เลขชุด</TableCell>
                        <TableCell  sx={{color : 'white'}}>สินค้า</TableCell>
                        <TableCell  sx={{color : 'white'}}>จำนวน</TableCell>
                        <TableCell  sx={{color : 'white'}}>เจ้าของ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {detail.map((item) => (
                        <React.Fragment key={item.id}>
                            <TableRow onClick={() => toggleRow(item.id)}>
                                <TableCell>{item.from_order}</TableCell>
                                <TableCell>{item.batch_no}</TableCell>
                                <TableCell>{item.product}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{item.owner}</TableCell>
                            </TableRow>
                            {expandedRows.includes(item.id) && item.farmers && item.farmers.map(farmer => (
                                <React.Fragment key={farmer.id}>
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow sx={{backgroundColor : '#214f47'}}>
                                                            <TableCell sx={{color : 'white'}}>เลขชุด</TableCell>
                                                            <TableCell sx={{color : 'white'}}>จำนวน</TableCell>
                                                            <TableCell sx={{color : 'white'}}>วันที่เก็บเกี่ยว</TableCell>
                                                            {/* <TableCell sx={{color : 'white'}}>เวลาเก็บเกี่ยว</TableCell> */}
                                                            <TableCell sx={{color : 'white'}}>เจ้าของ</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>{farmer.batch_no}</TableCell>
                                                            <TableCell>{farmer.quantity}</TableCell>
                                                            <TableCell>{moment(farmer.harvest.harvest_date).format('DD MMM YYYY')}</TableCell>
                                                            {/* <TableCell>{farmer.harvest.start_time} - {farmer.harvest.end_time}</TableCell> */}
                                                            <TableCell>{farmer.owner}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OrderMaterialAgent;
