import { useEffect, useState } from "react";
import ShippingTable from "../../../components/table/shipping-table";
import Layout from "../../../components/layout";
import { Box } from "@mui/material";
import { getCookie } from "../../../utils/cookie";
import { getOrdersByVendor, orderStatusChange } from "../../../services/orders";
import moment from "moment";
import AcceptRadio from "../../../components/orders/accept_popup";
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const [list_orders, setOrders] = useState([]);
    const [open, setOpen] = useState(false);
    const [order_id, setOrderId] = useState()
    const [option, setOption] = useState()
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('accessToken')
    const navigate = useNavigate()
    let total = 0

    useEffect(() => {
        fetchListOrders();
    }, []);

    const fetchListOrders = () => {
        getOrdersByVendor(user_id, token)
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.data;
                    console.log(data)
                    let list = [];
                    data.map(item => {
                        let attribute = item?.attribute[0]?.value ?? ''

                        list.push({
                            "id": item.id,
                            "orders_no": item.orders_no,
                            "orderer": item.user.firstname + ' ' + item.user.lastname,
                            "order_date": moment(item.create_date).format('DD MMM YYYY'),
                            "product": item.product.name + ' ' + attribute,
                            "quantity": `${item.total_weight.toLocaleString('en-US')} ${item.product.unit}`,
                            "status": item.status_desc
                        });
                    });
                    setOrders(list);
                }
            }).catch(error => {
                console.log(error)
                if (error.response.status === 403) {
                    navigate('/login')
                }
            })
    };

    const handleAcceptOpen = (id) => {
        setOrderId(id)
        setOpen(true);
    };

    const handleAcceptButton = () => {
        if (!option) {
         alert('กรุณาเลือกตัวเลือกก่อน')
        }
        else if (option === 'option1') {
            setOpen(false)

            window.location.href = '/orders/shipping_product/' + order_id
        } else {
            setOpen(false)

            orderStatusChange({
                user_id: user_id,
                status: 5,
                order_id: order_id
            }, token)
                .then((res) => {
                    alert('ปฏิเสธสินค้าเสร็จสิ้น')
                    fetchListOrders()
                }).catch(error => {
                    console.log(error)
                })

        }
    }

    return (
        <Layout title='รายการจัดส่ง Dashboard'>
            <Box my={2}>
                <ShippingTable rows={list_orders} handleAccept={handleAcceptOpen} />
                <AcceptRadio open={open} setOpen={setOpen} handleAccept={handleAcceptButton} setOption={setOption} option={option} />
            </Box>
        </Layout>
    );
};

export default Dashboard;
